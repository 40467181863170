import React from 'react';
import PropTypes from 'prop-types';
import {Tabs, Row, Col, Form, message, Popover, Modal, Button} from 'antd';
import AvatarEditor from 'react-avatar-editor';
import UserAvatar from 'react-user-avatar';
import {AvatarColors, IconTypes} from '@/constants/common';
import {REQUEST_BASE, getToken} from "@/utils/HttpUtil";
import {getBase64Url} from "@/utils/Common";
import frameStyle from '@/style/containers/mainView.accountCenter.less';
import style from '@/style/components/accountCenter/account.basicInfoModal.less';
import {ChangeUserBasicInfoStatus} from "@/constants/account";
import {checkUploadImage} from "@/components/common/common.functions";
import copy from 'copy-to-clipboard';

import ViewManager from '@/components/common/common.viewManager';
import ViewInfoCard from '@/components/dashboard/dashboard.viewInfoCard.userCenter';
import UserNickAvatar from "@/components/common/objects/common.userAvatar";
import NodeAvatar from "@/components/common/objects/common.nodeAvatar";
//import { API_GetRelationNodeList,API_GetSubGraph,API_LoadNodeListByUser,API_GetViewById,API_GetTeamworkMembers,API_LoadTeamworkSettings } from "@/libs/view/network/api.js";
import { API_GetRelationNodeList,API_GetSubGraph,API_LoadNodeListByUser,API_GetViewById,API_GetTeamworkMembers,API_LoadTeamworkSettings } from "@/libs/view/network/map_v2_api.js";
import moment from "moment";

// noinspection NpmUsedModulesInstalled
const chinaDivision = require('@/constants/area.nocode.json');

const avatarSize = 180;
const loadItemsPerStep = 10;

const TAB_TITLES = ['我是组长','我是成员','我的开放阅读','我的开放协作','我的开放复制','最近更新'];

class AccountUserCenterPanel extends React.Component {
    state = {
        dataChanged: false,
        uploading: false,
        uploadedAvatarImgBase64Url: null,
        showAvatarEditor: false,
        avatarWidth: avatarSize,
        avatarHeight: avatarSize,
        avatarScale: 1,
        avatarRotate: 0,
        avatarPicId: '',
        nick: '',
        name: '',
        org: '',
        province: '',
        city: '',

        activeKey: 'tab_id_0',
        hasMore: true,
        status: 'idle',
        filteredViewList: [],
        subGraphList: [],
        activeNodeId: '',
        subShow:false
    };

    pageStart = 0;
    filteredViewList = undefined;

    avatarEditorRef = undefined;
    
    onNickNameChanged = e => {
        if (e.target.value) {
            this.setState({dataChanged: true, nick: e.target.value});
        } else {
            this.setState({dataChanged: true});
        }
    };

    onFieldChanged = () => {
        this.setState({dataChanged: true});
    };

    resetFieldValues = () => {
        const {nick, name, avatarPicId, org, province, city} = this.props;
        let me = this;
        this.setState({
            nick, name, org, province, city, avatarPicId,
            uploadedAvatarImgBase64Url: null,
            dataChanged: false,
            showAvatarEditor: false,
        }, () => me.props.form.resetFields());
    };

    copyMemberId = (memberId) => {
        let result = copy(memberId, {
            message: '请按下 #{key} 复制选中节点文本。',
        });

        if (result) message.success('协作ID已经复制到剪贴板');
    };

    constructor(props) {
        super(props);
        const {nick, name, avatarPicId, org, province, city} = props;
        if (!this.state.dataChanged) {
            this.state = {
                ...this.state,
                nick,
                name,
                org,
                province,
                city,
                avatarPicId,
                uploadedAvatarImgBase64Url: null,
                showAvatarEditor: false,
            };
        }
    }

    onTabChange = activeKey => {
        let me = this;
        if (activeKey !== me.state.activeKey) {
          me.setState({activeKey}, () => {
            me.onLoadData();
          });
        }
    };

    onLoadData = () => {
        let me = this, limit = loadItemsPerStep;
        const currentUserId = localStorage.getItem('userId');
        me.setState({status: 'loading', filteredViewList: []}, () => {
            switch (me.state.activeKey) {
                case 'tab_id_0':
                    // 加载我是组长
                    ViewManager.loadUserIsMasterViewList(currentUserId, {q: '', e: '的事件关联图谱', start: this.pageStart, limit}).then(res => {
                        this.setState({status: 'success', filteredViewList: res, hasMore: res.length === limit}, () => {me.onLoadNodes(me.state.activeKey);});
                    }).catch(err => {
                        console.log(' 加载数据失败 ->  :', );
                        message.info('加载数据失败，请稍后再试');
                        me.setState({status: 'failed'});
                    });
                    break;
                case 'tab_id_1':
                    // 加载我是成员
                    ViewManager.loadUserIsMemberViewList(currentUserId, {q: '', start: this.pageStart, limit}).then(res => {
                        this.setState({status: 'success', filteredViewList: res, hasMore: res.length === limit}, () => {me.onLoadNodes(me.state.activeKey);});
                    }).catch(err => {
                        console.log(' 加载数据失败 ->  :', );
                        message.info('加载数据失败，请稍后再试');
                        me.setState({status: 'failed'});
                    });
                break;
                case 'tab_id_2':
                        // 我的开放阅读 
                        ViewManager.loadUserIsMasterViewList(currentUserId, {q: '', e: '的事件关联图谱', start: 0, limit:100}).then(res => {
                            let filteredViewList=[],success_num=0,res_my = res.filter(item => {
                                return item.teamworkType !== 1 && !(item.up === 1 && item.price === 0);
                            })
                            res_my.map(async (item,index) => {
                                let displayType = await this.getTeamworkSettings(item.viewId);
                                displayType===2 && filteredViewList.push(item);
                                success_num++;
                                success_num===res_my.length && this.setState({status: 'success', filteredViewList: filteredViewList}, () => {me.onLoadNodes(me.state.activeKey);});
                            })
                        }).catch(err => {
                            console.log(' 加载数据失败 ->  :', );
                            message.info('加载数据失败，请稍后再试');
                            me.setState({status: 'failed'});
                        });
                break;
                case 'tab_id_3':
                        // 我的开放协作
                        ViewManager.loadUserIsMasterViewList(currentUserId, {q: '', e: '的事件关联图谱', start: 0, limit:100}).then(res => {
                            let res_my = res.filter(item => {
                                return item.teamworkType === 1;
                            })
                            this.setState({status: 'success', filteredViewList: res_my}, () => {me.onLoadNodes(me.state.activeKey);});
                        }).catch(err => {
                            console.log(' 加载数据失败 ->  :', );
                            message.info('加载数据失败，请稍后再试');
                            me.setState({status: 'failed'});
                        });
                break;
                case 'tab_id_4':
                        // 我的开放复制
                        ViewManager.loadUserIsMasterViewList(currentUserId, {q: '', e: '的事件关联图谱', start: 0, limit:100}).then(res => {
                            let res_my = res.filter(item => {
                                return item.up === 1 && item.price === 0;
                            })
                            this.setState({status: 'success', filteredViewList: res_my}, () => {me.onLoadNodes(me.state.activeKey);});
                        }).catch(err => {
                            console.log(' 加载数据失败 ->  :', );
                            message.info('加载数据失败，请稍后再试');
                            me.setState({status: 'failed'});
                        });
                break;
                case 'tab_id_5':
                    // 最近更新
                    let startDay = moment().hour(0).minute(0).second(0).millisecond(0).subtract(7, 'd');
                    API_LoadNodeListByUser(currentUserId, startDay.format('YYYY-MM-DD')).then(response => {
                        if (response && response.data && response.data.code === 0) {
                            let nodes = response.data.data.length>0 ? response.data.data.sort((nodeA, nodeB) => {
                                    return nodeA['updateTime'] === nodeB['updateTime'] ? 0 : (nodeA['updateTime'] < nodeB['updateTime'] ? 1 : -1)
                                }) :[];
                            let view_id_list = [];
                            nodes.slice(0,30).map(node => {
                                !view_id_list.includes(node.viewId) && view_id_list.push(node.viewId);
                            })
                            if(view_id_list.length>0){
                                me.onLoadViews(view_id_list,nodes,me.state.activeKey);
                            }else{
                                this.setState({status: 'success', filteredViewList: []}); 
                            }
                        } else {
                            console.log(' 加载节点失败 ->  :',response);
                                //message.info('加载数据失败，请稍后再试');
                            me.setState({status: 'failed'});
                        }
                    }).catch(error => {
                            console.log(' 加载节点失败 ->  :',error );
                            message.info('加载数据失败，请稍后再试');
                            me.setState({status: 'failed'});
                    });
                    break;
                default:
                    // 默认 all = 可访问的图谱
                    ViewManager.loadAccessibleViewList({q: '', start: this.pageStart, limit}).then(res => {
                        this.setState({status: 'success', filteredViewList: res, hasMore: res.length === limit}, () => {me.onLoadNodes();});
                    }).catch(err => {
                        console.log(' 加载数据失败 ->  :', );
                        message.info('加载数据失败，请稍后再试');
                        me.setState({status: 'failed'});
                    });
            }
        
        });
    };

      onLoadViews = (view_id_list,nodes,tab_key) => {
        let filteredViewList = [],success_num=0;
        view_id_list.length>0 && view_id_list.map(async (viewId,index) => {
            if(this.state.activeKey===tab_key){
                let res = await API_GetViewById(viewId);
                if (res && res.data && res.data.code === 0) {
                    let view = res.data.data;
                    view.members = await this.getMembers(viewId);
                    filteredViewList.push(view);
                    success_num++;
                    success_num === view_id_list.length && this.setState({status: 'success', filteredViewList}, () => {this.onExNodes(nodes);});
                }else{
                    success_num++;
                    success_num === view_id_list.length && this.setState({status: 'success', filteredViewList}, () => {this.onExNodes(nodes);});
                }
            }
        });
      }

      onExNodes = (nodes) => {
        let filteredViewList =this.state.filteredViewList;
        nodes.map(node => {
            filteredViewList.map((view,index) => {
                if(view.viewId == node.viewId){
                    node.userInfo = view.members.find( member => member.userId===node.userId);
                    if(filteredViewList[index].nodes && filteredViewList[index].nodes.length>0){
                        filteredViewList[index].nodes.length<8 && filteredViewList[index].nodes.push(node);
                    }else{
                        filteredViewList[index].nodes = [node];
                    }
                }
            })
        })
        this.setState({filteredViewList});
      }
      
      getTeamworkSettings = async (viewId) => {
        let res = await API_LoadTeamworkSettings(viewId);
        if (res && res.data && res.data.code === 0) {
            let r = res.data.data;
            let joinType = r.type,
				sharingType = r.meta && r.meta['public'] > 0 ? 1 : (joinType === 0 ? 0 : 3),
				displayType = sharingType === 1 ? 2 : joinType;
            return displayType;
        }else{
            return 0;
        }
      }

      getMembers = async (viewId) => {
        let limit = -1, orderBy = undefined, orderType = undefined, start = 0
        let res = await API_GetTeamworkMembers(viewId, limit, orderBy, orderType, start);
        if (res && res.data && res.data.code === 0) {
            return res.data.data;
        }else{
            return [];
        }
      }

      onLoadNodes = (tab_key) => {
        let filteredViewList = this.state.filteredViewList;
        filteredViewList.length>0 && filteredViewList.map(async (view,index) => {
            if(this.state.activeKey===tab_key){
                filteredViewList[index].members = await this.getMembers(view.viewId);
                let res = await API_GetRelationNodeList(view.viewId, {userConfirmed: 1}, 0, 8, 'update_time', 'DESC');
                let nodeList = res.data.data;
                nodeList.map((node,idx_n) => {
                    nodeList[idx_n].userInfo = filteredViewList[index].members.find( member => member.userId===node.userId);
                })
                filteredViewList[index].nodes = nodeList;
                this.setState({filteredViewList});
            }
        });
      }
    showSubGraph = (viewId,activeNodeId,idx_v,idx_n) =>{
        let me = this;
        if(!me.state.subShow || me.state.activeNodeId!==activeNodeId){
          if(me.state.activeNodeId==activeNodeId){
            this.setState({subShow:true});
          }else{
            this.setState({subShow:true,activeNodeId:activeNodeId,subGraphList:[]}, () => {
                ViewManager.loadSubGraphNodesList(viewId,{ related_node_ids: activeNodeId, with_detail: 0, limit: 300}).then(res => {
                    let result = res.map(item => {
                        let { nodeId, graph: { edges, id, nodes} } = item;
                        let allNodesMap = {}, nodeConnectionsMap = {};
                        if (edges && edges.length > 0) {
                            edges.forEach(edge => {
                                if (!nodeConnectionsMap[edge.from]) nodeConnectionsMap[edge.from] = [];
                                if (!nodeConnectionsMap[edge.to]) nodeConnectionsMap[edge.to] = [];
                                if (nodeConnectionsMap[edge.from].indexOf(edge.to) === -1) {
                                    nodeConnectionsMap[edge.from].push(edge.to);
                                }
                                if (nodeConnectionsMap[edge.to].indexOf(edge.from) === -1) {
                                    nodeConnectionsMap[edge.to].push(edge.from);
                                }
                            });
                        }
                        if (nodes && nodes.length > 0) {
                            nodes.forEach((node) => {
                                node.userInfo = me.state.filteredViewList[idx_v].members.find( member => member.userId===node.userId);
                                allNodesMap[node.id] = node;
                            })
                        }
                        let tempGroup = allNodesMap[nodeId] || originalNodes[nodeId];
                        tempGroup['childList'] = [];
                        if (nodeConnectionsMap[tempGroup.id]) {
                            tempGroup.child = true;
                            nodeConnectionsMap[tempGroup.id].forEach(id => {
                                if (id !== tempGroup.id && allNodesMap[id]) {
                                    tempGroup.childList.push(allNodesMap[id]);
                                }
                            });
                        }
                        
                        if (tempGroup.childList.length > 0) {
                            tempGroup.childList = tempGroup.childList.map(node => {
                                node['childList'] = [];
                                if (nodeConnectionsMap[node.id]) {
                                    node.child = true;
                                    nodeConnectionsMap[node.id].forEach(id => {
                                        if (id !== node.id && id !== tempGroup.id && allNodesMap[id]) {
                                            node.childList.push(allNodesMap[id]);
                                        }
                                    })
                                }
                                //node.childList = this.sortStringList(node.childList);
                                return node;
                            })
                        }
                        return tempGroup;
                    })
                    me.setState({
                        subGraphList: result[0].childList,
                    });     
                }).catch(err => {
                    console.log(' 加载数据失败 ->  :', );
                    message.info('加载数据失败，请稍后再试');
                });
            });
          }
        }else{
            this.setState({subShow:false})
        }
    }
    
    componentDidMount() {
		this.onLoadData();
	}

    componentWillReceiveProps(nextProps, nextState) {
        if (nextProps) {
            const {nick, name, avatarPicId, org, province, city, changeUserBasicInfoStatus} = nextProps;
            if (this.props.changeUserBasicInfoStatus === ChangeUserBasicInfoStatus.PROCESSING &&
                changeUserBasicInfoStatus === ChangeUserBasicInfoStatus.SUCCESS) {
                // 保存用户信息成功，刷新数据
                requestAnimationFrame(this.resetFieldValues);
            } else if ((!nextState && this.state.dataChanged === false) || (nextState && nextState.dataChanged === false)) {
                // 用户未修改，数据有刷新
                this.setState({
                    nick, name, org, province, city, avatarPicId,
                    uploadedAvatarImgBase64Url: null,
                    showAvatarEditor: false,
                });
            }
        }
    }

    render() {
        let me = this, tabContent=null;
        const {visible, onCancel ,onEdit} = this.props;
        if (me.state.status === 'success') {
            tabContent = this.state.filteredViewList.length>0 ? this.state.filteredViewList.map((view,idx_v ) => (
                <Row className={style["views-list"]}>
                    <Col span={12}>
                        <ViewInfoCard
                            readOnly={true}
                            showCornerTip={true}
                            callback={()=>{}}
                            view={view}
                            key={`view-${view.viewId}`}
                            module={'all'}
                        />
                    </Col>
                    <Col offset={0} span={12}>
                        <div className={style["node-list"]}>
                            {view["nodes"] && view["nodes"].map((node, idx_n) => {
                                return (
                                    <div key={"n-" + idx_n} className={style["node-item"]} style={{clear: 'both'}}>
                                        <div className={`${style['node-item-body']} ${style['flex-sub']}`}>
                                            <div className={`${style['node-item-inner']} ${style['flex-sub']}`}>
                                                <div className={`${style['flex']} ${style['align-center']}`} onClick={(e) => {e.stopPropagation();me.showSubGraph(view.viewId,node.id,idx_v,idx_n);}} style={{cursor:'pointer'}}>
                                                    <UserNickAvatar className={style["user"]} user={node.userInfo} avatarSize="1rem" showName={false}/>
                                                    <Popover placement="right" content={node.label} trigger="hover"><div className={style['node-info']}>{node.fname}</div></Popover>
                                                </div>
                                            </div>
                                            <div className={`${style['sub-node-list']} ${style['sub-node-list-ex']}`}>
                                                {me.state.subShow && me.state.activeNodeId==node.id && me.state.subGraphList && me.state.subGraphList.map((subNode, idx3) => {
                                                    return (
                                                        <div key={`node-item-${idx3}`} className={`${style['node-item']}`}>
                                                            <div className={style['tree-line']}>
                                                                <div className={`${style['tree-line-inner']} ${subNode.childList.length > 0 ? style['across']:''}`}></div>
                                                            </div>
                                                            <div className={`${style['node-item-body']} ${style['flex-sub']}`}>
                                                                {!subNode.childList || subNode.childList.length === 0 && (<div className={`${style['node-item-inner']} ${style['flex-sub']}`}>
                                                                    <div className={`${style['node-item-inner-body']} ${style['flex']} ${style['align-center']}`}>
                                                                        <UserNickAvatar className={style["user"]} user={subNode.userInfo} userId={subNode.userId} avatarSize="1rem" showName={false}/>
                                                                        <Popover placement="right" content={subNode.label} trigger="hover"><div className={style['node-info']}>{subNode.fname}</div></Popover>
                                                                    </div>
                                                                </div>)}
                                                                {subNode.childList.length > 0 && (<div className={`${style['node-item-inner']} ${style['flex-sub']} ${style['right']}`}>
                                                                    <div className={`${style['node-item-inner-body']} ${style['flex']} ${style['align-center']}`}>
                                                                        <div className={style['node-info']}><Popover placement="right" content={subNode.label} trigger="hover">{subNode.fname}</Popover></div>
                                                                        <UserNickAvatar className={style["user"]} user={subNode.userInfo} userId={subNode.userId} avatarSize="1rem" showName={false}/>
                                                                    </div>
                                                                </div>)}
                                                                {subNode.childList.length > 0 && (<div className={`${style['sub-sub-node-list']} ${style['right']}`}>
                                                                    {subNode.childList.map((subsubNode, subsubIndex) => {
                                                                        return (<div key={`node-item-${subsubIndex}`} className={`${style['node-item']} ${style['justify-end']}`}>
                                                                            <div className={`${style['node-item-inner']}`}>
                                                                                <div className={`${style['flex']} ${style['align-center']}`}>
                                                                                    <div className={style['node-info']}><Popover placement="right" content={subsubNode.label} trigger="hover">{subsubNode.fname}</Popover></div>
                                                                                    <UserNickAvatar className={style["user"]} user={subsubNode.userInfo} userId={subsubNode.userId} avatarSize="1rem" showName={false}/>
                                                                                </div>
                                                                            </div>
                                                                            <div className={`${style['tree-line']} ${style['right']}`}>
                                                                                <div className={style['tree-line-inner']}></div>
                                                                            </div>
                                                                        </div>)})}
                                                                    </div>)}
                                                            </div>
                                                        </div>)})}
                                            </div>
                                        </div>
                                    </div>)})}
                        </div>
                    </Col>
                </Row>
            )) : (
              <div className={style['user-center-body-tab-content-empty']}>
                没有找到相关内容
              </div>
            );
          } else if (me.state.status === 'failed') {
            tabContent = (
              <div className={style['user-center-body-tab-content-loading']}>
                加载失败，点击重试
              </div>
            );
          } else {
            tabContent = (<div className={style['user-center-body-tab-content-loading']}>加载中...</div>)
          }
        return (
            <Modal
                visible={visible}
                title={'个人中心'}
                maskClosable={true}
                centered={true}
                width={960}
                height={650}
                onCancel={onCancel}
                destroyOnClose={true}
                footer={null}
                top={-100}
            >
                <Row>
                    <Col span={16}>
                        <div className={`${style['content-info']} ${style['frame']}`}>
                            {this.props.nick && <div>
                                <span>昵称：</span>
                                <span>{this.props.nick}</span>
                                <Button style={{float:'right'}} icon={'edit'} onClick={() =>{onCancel();onEdit();}}>编辑基本信息</Button>
                            </div>}
                            {this.props.level_name && <div>
                                <span>会员等级：</span>
                                <span>{this.props.level_name}</span>
                            </div>}
                            {this.props.level>1 && this.props.level_info && this.props.level_info && <div>
                                <span>到期日期：</span>
                                <span>{this.props.level_info.expire_time.substring(0,10)}</span>
                            </div>}
                            {this.props.name && <div>
                                <span>姓名：</span>
                                <span>{this.props.name}</span>
                            </div>}
                            {this.props.org && <div>
                                <span>公司/机构：</span>
                                <span>{this.props.org}</span>
                            </div>}
                            {this.props.province && <div>
                                <span>地区：</span>
                                <span>{this.props.province}{this.props.city}</span>
                            </div>}
                            <div>
                                <span>协作ID：</span>
                                <span>{this.props.accessToken}</span>
                                <p className={style['copy-tips']}
                                      onClick={() => me.copyMemberId(this.props.accessToken)}>
                                    （<u>复制协作ID</u>&nbsp;&nbsp;类似QQ号&nbsp;&nbsp;可发送给朋友&nbsp;&nbsp;用于协作）
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col offset={2} span={6} style={{marginBottom:'1rem'}}>
                        <UserAvatar size={avatarSize} name={me.props.nick}
                        src={me.props.avatarPicId ?  `${REQUEST_BASE}/user/user/file/${me.props.avatarPicId}?Authorization=${getToken()}` : undefined}
                        className={me.props.avatarPicId ? null : style['card-avatar-default-icon']}
                        style={{width: 'fit-content',fontSize: avatarSize / 2.5, }}/>
                    </Col>
                </Row>
                <div className={`${frameStyle['content-inner']} ${style['frame']}`} style={{maxHeight:'600px'}}>
                    <Tabs
                    size={'small'}
                    activeKey={me.state.activeKey}
                    animated={false}
                    tabBarGutter={0}
                    onChange={me.onTabChange}
                    >
                        {TAB_TITLES.map((tab_name,index) => (
                            <Tabs.TabPane key={'tab_id_'+index} tab={tab_name} forceRender={true} className={`${style['user-center-body-tab-content']} scrollbar scrollbar-none`}>
                                <div style={{width:'100%'}}>
                                {me.state.activeKey === 'tab_id_'+index ? tabContent : null}
                                </div>
                            </Tabs.TabPane>
                        ))}
                    </Tabs>
                </div>
            </Modal>
        );
    }
}

const WrappedAccountUserCenterPanel = Form.create()(AccountUserCenterPanel);

WrappedAccountUserCenterPanel.defaultProps = {
    onSaveUserBasicInfo: (userInfo) => {
        console.log('property onSaveUserBasicInfo is not set, userInfo: ', userInfo);
    },
};

WrappedAccountUserCenterPanel.propTypes = {
    nick: PropTypes.string.isRequired,
    name: PropTypes.string,
    avatarPicId: PropTypes.string,
    org: PropTypes.string,
    province: PropTypes.string,
    city: PropTypes.string,
    accessToken: PropTypes.string,
    changeUserBasicInfoStatus: PropTypes.number,
    onSaveUserBasicInfo: PropTypes.func,
    level: PropTypes.number,
    level_name: PropTypes.string,
    level_info: PropTypes.object,
};

export default WrappedAccountUserCenterPanel;
