import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Popover,
  Row,
  Tag,
  Tooltip,
  Checkbox
} from "antd";

import PB, {SimplePB} from "@/libs/simplePB";

import Icon from "@/components/common/common.icon";
import ViewManager from "@/components/common/common.viewManager";

import style from "@/style/common/view/common.view.info.setInfoModal.less";
import { IconTypes } from "@/constants/common";
// 创建图谱时，选择模板数据
import createViewTemplate from "@/assets/view-template/create-view-template";
import intl from 'react-intl-universal';

class createInfoModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      desc: "",
      selectedTemplate: createViewTemplate[0],
      status: "idle",
      tags: [],
      currentTag: "", // 当前输入标签名称
      historyTags: [], // 历史记录标签
      showTagInputTooltip: false,
      recommendTags: [
        "大学",
        "高中",
        "考研",
        "写作",
        "政治",
        "计算机",
        "教学",
        "读书笔记",
      ],
    };
  }

  addTagInputRef = undefined; // 标签输入框

  componentDidMount() {
    requestAnimationFrame(() => {
      createViewTemplate.forEach((template, index) => {
        template.hasSnapshot = true;
        template.snapshot = `/assets/view-templates/view-template-${index}.png`;
      });
    });
  }

  onSave = () => {
    let me = this;
    const { form, callback } = me.props;
    const currentUserId = localStorage.getItem("userId");
    form.validateFields(async (err, values) => {
      if (!err) {
        const { name, desc, channel, tags } = values;

        me.setState(
          {
            status: "processing",
          },
          () => {
            const newInfo = {
              ...me.props.viewInfo,
              name,
              desc,
              channel,
              tags,
              free: 0,
              initialRelation: me.state.selectedTemplate.initialRelation,
            };

            ViewManager.addView(newInfo, currentUserId)
              .then((res) => {
                me.setState({
                  status: "success",
                });
                me.onClose();
                message.success("图谱创建成功");
                callback &&
                  callback({ action: "create", success: true, viewInfo: res });
              })
              .catch((e) => {
                me.setState({
                  status: "fail",
                });
                if(e.code==190001){
                  message.error(e.msg, 1).then(() => {
                    PB.emit('account', 'user_upgrade.show_modal', {});
                  });
                }else{
                  message.error("网络不稳定，请尝试重新保存。", 3);
                  callback && callback({ action: "create", success: false });
                }
              });
          }
        );
      }
    });
  };

  onClose = () => {
    const me = this;
    me.setState({
      selectedTemplate: createViewTemplate[0],
    });
    me.props.onClose();
  };

  onTagAdd = (tag) => {
    const { form } = this.props;
    let tags = Array.from(form.getFieldValue("tags"));
    if (!tag) {
      message.error("请输入标签内容");
    } else if (tag.includes(" ")) {
      message.error("标签内容不可含有空格");
    } else if (tags.includes(tag)) {
      message.error("该标签已存在");
      this.setState({ currentTag: "" });
    } else {
      tags = tags.concat(tag);

      // 将标签缓存到本地
      let historyTags = localStorage.getItem("historyViewTags") || "";
      if (historyTags) {
        historyTags = historyTags.includes(tag)
          ? historyTags
          : `${tag}|${historyTags}`;
      } else {
        historyTags = `${tag}`;
      }

      localStorage.setItem("historyViewTags", historyTags);
      // can use data-binding to set
      form.setFieldsValue({
        tags: tags,
      });
      this.setState({
        tags: tags,
        currentTag: "",
        historyTags: historyTags.split("|"),
      });
    }
  };

  onTagRemove = (i) => {
    const { form } = this.props;
    // can use data-binding to get
    let tags = Array.from(form.getFieldValue("tags"));
    tags.splice(i, 1);

    // can use data-binding to set
    form.setFieldsValue({
      tags: tags,
    });
    this.setState({ tags: tags });
  };

  onChangeTemplate = (e) => {
    const me = this;
    if(e.target.checked){
      me.setState({
        selectedTemplate: createViewTemplate[0],
      });
    }else{
      me.setState({
        selectedTemplate: {
          templateId: -1,
          viewId: null,
          title: "空白",
          description: "",
          tag: "新建图谱模板",
          url: null,
          initialRelation: null,
          hasSnapshot: true,
          snapshot: `/assets/view-templates/view-template-blank.png`,
        },
      })
    }
  };

  render() {
    let me = this;
    const {
      form,
      visible = {},
      params: { channel = 0 },
    } = me.props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      // labelCol: {span: 24},
      // wrapperCol: {span: 24},
    };
    getFieldDecorator("tags", { initialValue: this.state.tags || [] });
    return (
      <Modal
        title={
          <div>
            <Icon name="edit" style={{ color: "#a5a5a5", marginRight: 10 }} />
            {intl.get('Custom.view.add')}
          </div>
        }
        visible={visible}
        okText={"确定"}
        cancelText={"取消"}
        centered={true}
        destroyOnClose={true}
        maskClosable={false}
        width={550}
        onOk={() => me.onSave()}
        okButtonProps={{
          loading: this.state.status === "processing",
          disabled: this.state.status === "processing",
        }}
        onCancel={() => me.onClose()}
        wrapClassName={"custom-modal-confirm"}
      >
        <Form className={style["custom-form-wrap"]}>
          {/* 隐藏字段: channel */}
          {getFieldDecorator("channel", { initialValue: channel })(
            <Input hidden />
          )}

          {/* 以下正常填写字段 */}
          <Form.Item
            {...formItemLayout}
            label={
              <span>
                标题
                  <span style={{ fontSize: "14px" }}>
                    （会被所有用户搜索到，请避免隐私词汇
                    <Popover
                      content={
                        <div style={{ width: "350px", fontSize: "14px" }}>
                          <p>
                            图谱标题为公开信息，会被所有用户搜索到，请避免隐私词汇的使用。
                          </p>
                          <p style={{ margin: 0 }}>
                            图谱内容是否公开，由组长在图谱访问设置中进行设置。
                            <br />
                            （访问设置位置：“菜单 -&gt; 图谱设置 -&gt; 访问设置”）
                          </p>
                        </div>
                      }
                      placement="top"
                    >
                      <Icon
                        type={IconTypes.ICON_FONT}
                        name="icon-yibantishi"
                        style={{ padding: "0.1rem" }}
                      />
                    </Popover>
                    ）
                  </span>
              </span>
            }
          >
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "请输入图谱标题",
                },
              ],
            })(<Input autoFocus={true} />)}
          </Form.Item>
          <Form.Item {...formItemLayout} label="描述：">
            {getFieldDecorator("desc")(<Input.TextArea rows={4} />)}
          </Form.Item>

          {/* 标签 */}
          {channel > 0 ? (
            <Form.Item {...formItemLayout} label="标签：">
              {this.state.tags.map((tag, i) => (
                <Tag key={`tag-${i}`}>
                  &nbsp;{tag}&nbsp;
                  <a
                    className={style["tag-remove"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      me.onTagRemove(i);
                    }}
                  >
                    <Icon name={"delete"} />
                  </a>
                </Tag>
              ))}
              <Tooltip
                title={
                  <div>
                    <Input.Search
                      placeholder="请输入标签"
                      enterButton="添加"
                      value={this.state.currentTag}
                      ref={(inputRef) => {
                        me.addTagInputRef = inputRef;
                      }}
                      onChange={(e) => {
                        let value = e.target.value;
                        let tags =
                          localStorage.getItem("historyViewTags") || "";
                        let historyTags = (tags && tags.split("|")) || [];
                        let filterTags = historyTags.filter((item) =>
                          item.includes(value)
                        );
                        if (value) {
                          this.setState({
                            currentTag: e.target.value,
                            historyTags: filterTags,
                          });
                        } else {
                          this.setState({
                            currentTag: e.target.value,
                            historyTags: historyTags,
                          });
                        }
                      }}
                      onSearch={this.onTagAdd}
                      onKeyDown={(event) => {
                        if (event.key === "Escape") {
                          event.stopPropagation();
                          me.setState({
                            showTagInputTooltip: false,
                            currentTag: "",
                          });
                        }
                      }}
                    />
                    {me.state.currentTag ? (
                      <div className={style["tag-shortcut-wrap"]}>
                        {me.state.historyTags.length > 0 ? (
                          <React.Fragment>
                            <div className={style["tag-group-title"]}>
                              输入匹配：
                            </div>
                            <div className={style["tag-group"]}>
                              {me.state.historyTags.map((item, index) => {
                                if (index < 3) {
                                  return (
                                    <div
                                      key={item}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <Tag
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          this.onTagAdd(item);
                                        }}
                                      >
                                        {item}
                                      </Tag>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </React.Fragment>
                        ) : null}
                      </div>
                    ) : (
                      <div className={style["tag-shortcut-wrap"]}>
                        {me.state.historyTags.length > 0 ? (
                          <React.Fragment>
                            <div className={style["tag-group-title"]}>
                              历史记录：
                            </div>
                            <div className={style["tag-group"]}>
                              {me.state.historyTags.map((item, index) => {
                                if (index < 8) {
                                  return (
                                    <div
                                      key={item}
                                      style={{
                                        display: "inline-block",
                                        marginBottom: "10px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <Tag
                                        onClick={() => {
                                          this.onTagAdd(item);
                                        }}
                                      >
                                        {item}
                                      </Tag>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </React.Fragment>
                        ) : null}
                      </div>
                    )}
                  </div>
                }
                placement="bottom"
                overlayClassName={style["add-tag-tooltip"]}
                trigger={"click"}
                visible={me.state.showTagInputTooltip}
                onVisibleChange={(visible) => {
                  me.setState({ showTagInputTooltip: visible });
                  if (visible) {
                    requestAnimationFrame(() => {
                      if (me.addTagInputRef) {
                        me.addTagInputRef.focus();
                      }
                      let tags = localStorage.getItem("historyViewTags") || "";
                      if (tags) {
                        me.setState({
                          historyTags: tags.split("|"),
                        });
                      }
                    });
                  }
                }}
              >
                <Button shape="circle" icon="plus" size={"small"} />
              </Tooltip>
            </Form.Item>
          ) : null}
          {/* 教育导航词 */}

          {channel > 0 ? (
            <div>
              <div style={{ marginBottom: "5px" }}>推荐标签:</div>
              {this.state.recommendTags.map((w) => {
                return (
                  <div
                    key={w}
                    style={{
                      display: "inline-block",
                      marginBottom: "10px",
                    }}
                  >
                    <Tag
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.onTagAdd(w);
                      }}
                    >
                      {w}
                    </Tag>
                  </div>
                );
              })}
            </div>
          ) : null}

          <br />
          <Form.Item {...formItemLayout} label="">
            <Checkbox defaultChecked onChange={(e) => {me.onChangeTemplate(e)}}>生成示例节点</Checkbox>
          </Form.Item>
          {/*
          <Form.Item {...formItemLayout} label="选择模板：">
            <Row style={{ marginBottom: "12px" }}>
              <Col span={7}>
                <Button
                  key={`view-template-blank`}
                  className={style["view-template-btn"]}
                  type={
                    me.state.selectedTemplate.templateId === -1
                      ? "primary"
                      : "default"
                  }
                  ghost={me.state.selectedTemplate.templateId === -1}
                  onClick={() => {
                    me.setState({
                      selectedTemplate: {
                        templateId: -1,
                        viewId: null,
                        title: "空白",
                        description: "",
                        tag: "新建图谱模板",
                        url: null,
                        initialRelation: null,
                        hasSnapshot: true,
                        snapshot: `/assets/view-templates/view-template-blank.png`,
                      },
                    });
                  }}
                >
                  空白
                </Button>
                {createViewTemplate.map((template, index) => (
                  <Button
                    key={`view-template-${index}`}
                    className={style["view-template-btn"]}
                    type={
                      me.state.selectedTemplate.templateId ===
                      template.templateId
                        ? "primary"
                        : "default"
                    }
                    ghost={
                      me.state.selectedTemplate.templateId ===
                      template.templateId
                    }
                    onClick={() => {
                      me.setState({
                        selectedTemplate: template,
                      });
                    }}
                  >
                    {template.title}
                  </Button>
                ))}
              </Col>
              <Col span={16}>
                <div className={style["view-template-snapshot"]}>
                  {me.state.selectedTemplate.hasSnapshot ? (
                    <img
                      src={me.state.selectedTemplate.snapshot}
                      alt={me.state.selectedTemplate.title}
                    />
                  ) : (
                    <div>
                      <img
                        src={me.state.selectedTemplate.snapshot}
                        alt={"构图说明"}
                      />
                      <div className={style["tips"]}>正在构图 请稍后再试</div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Form.Item>
          */}
        </Form>
      </Modal>
    );
  }
}

const WrappedCreateInfoModal = Form.create()(createInfoModal);

WrappedCreateInfoModal.defaultProps = {
  bus: PB,
  visible: false,
  callback: () => {},
  params: {
    channel: 0,
  },
};

WrappedCreateInfoModal.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  callback: PropTypes.func,
  params: PropTypes.object, // 额外的配置参数。eg: channel专题频道
};

export default WrappedCreateInfoModal;
